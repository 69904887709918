import { Link } from "gatsby";
import * as React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SEO from "../components/SEO";
import "../styles/voorwaarden.scss";

const AlgemeneVoorwaarden: React.FC<any> = (props) => {
    const { location } = props;

    const isSelected = (path: string) => {
        if (location.pathname.replace("/", "") === path) {
            return "voorwaarden-link-is-selected";
        }
        return ""
    }

    return (
        <>
            <SEO
                title="Voorwaarden - Leading Apps"
                description={"Leading App's algemene voorwaarden"}
                article={false}
            />

            <Header />

            <section id="doelgericht">

                <div className="section-spacer"></div>

                <div className="voorwaarden g-0 width-wrapper-medium mpb-sm">
                    <div className="col-md-10">

                        <div className="voorwaarden">
                            <div className="row justify-content-center">
                                <div className="col-10">
                                    <div className="mb-5">
                                        <h1 className="mb-4 bold"><b>Algemene Voorwaarden</b></h1>
                                        <p className="italics grey mb-5">Versie 14 juni 2021</p>
                                        <p className="bold">Leading Apps, gevestigd aan Pasakker 73, 5712 HS te Someren</p>
                                    </div>

                                    <div className="mt-3 pt-3">
                                        <h2 className="bold mb-3 ">1. Definities</h2>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Definitie</th>
                                                    <th>Uitleg</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Leading Apps
                                                    </td>
                                                    <td>
                                                        Leading Apps gevestigd aan het Kloosterweg 39 te (5711 GJ) Someren, ingeschreven bij de Kamer van Koophandel onder nummer 82956472.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Opdrachtgever
                                                    </td>
                                                    <td>
                                                        Degene die gebruik maakt van de diensten van Leading Apps, aan Leading Apps werkzaamheden opdraagt of Leading Apps verzoekt een offerte uit te brengen.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3">2. Toepasselijkheid</h2>
                                        <ol>
                                            <li>Deze algemene voorwaarden zijn van toepassing op de totstandkoming,
                                                de inhoud en de nakoming van alle tussen de Klant en Leading Apps gesloten overeenkomsten. Indien partijen van enige bepaling van deze voorwaarden wensen af te wijken, dient zulks schriftelijk te worden vastgelegd.</li>
                                            <li>Wanneer door Leading Apps gedurende korte of langere tijd al dan niet stilzwijgend afwijkingen van onderhavige algemene voorwaarden zijn toegestaan, laat dat onverlet haar recht alsnog directe en strikte naleving van deze voorwaarden te eisen. De Klant kan geen rechten ontlenen aan de wijze waarop Leading Apps de onderhavige voorwaarden toepast.</li>
                                            <li>Indien een of meer van de bepalingen van onderhavige algemene voorwaarden of enige andere Overeenkomst met Leading Apps in strijd mochten zijn met een dwingende wetsbepaling of enig toepasselijk rechtsvoorschrift, zal de betreffende bepaling komen te vervallen en zal deze worden vervangen door een door Leading Apps vast te stellen nieuwe, rechtens toelaatbare en vergelijkbare bepaling.</li>
                                            <li>De Klant met wie eenmaal op de onderhavige voorwaarden werd gecontracteerd, wordt geacht stilzwijgend met de toepasselijkheid van deze voorwaarden op een later met Leading Apps gesloten Overeenkomst in te stemmen.</li>
                                            <li>In geval van strijd tussen de inhoud van een tussen de Klant en Leading Apps gesloten Overeenkomst en de onderhavige voorwaarden, prevaleert de inhoud van de Overeenkomst.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">3. Overeenkomst, offerte en bevestiging</h2>
                                        <ol>
                                            <li>De door Leading Apps gemaakte offertes zijn geheel vrijblijvend. Prijsopgaven kunnen wijzigingen ondergaan door een onvoorziene verandering in de werkzaamheden.</li>
                                            <li>Voor zover de opdrachtgever een aanbieding van Leading Apps volledig en onvoorwaardelijk heeft aanvaard, kan Leading Apps dit aanbod herroepen binnen een periode van twee (2) dagen na aanvaarding.</li>
                                            <li>Indien de offerte weliswaar niet schriftelijk door de opdrachtgever werd bevestigd, maar de opdrachtgever er desondanks mee instemt dat Leading Apps een aanvang met het uitvoeren van de opdracht maakt, dan zal de inhoud van de offerte als overeengekomen gelden. Nadere mondelinge afspraken en bedingen binden Leading Apps eerst nadat deze schriftelijk door Leading Apps zijn bevestigd. Schriftelijke bevestiging is mogelijk per e-mail, mits de ontvangst van de betreffende e-mail schriftelijk (per e-mail) bevestigd is door de ontvanger.</li>
                                            <li>Afwijkingen van gedane aanbiedingen binden Leading Apps alleen, indien deze door Leading Apps schriftelijk zijn bevestigd.</li>
                                            <li>Wanneer de opdrachtgever eenzelfde opdracht tegelijkertijd aan anderen dan Leading Apps wenst te verstrekken, dient hij Leading Apps hiervan, onder vermelding van de namen van de andere ontwikkelaar/ontwikkelingsbureaus, op de hoogte te stellen. Indien een opdrachtgever eenzelfde opdracht reeds eerder aan een andere ontwikkelaar/ontwikkelingsbureaus heeft verstrekt, dient hij mee te delen welke ontwikkelaar/ontwikkelingsbureaus dit is geweest.</li>
                                            <li>Leading Apps noch de opdrachtgever zijn gerechtigd hun rechten of plichten voortvloeiend uit gesloten overeenkomsten over te dragen aan een derde partij zonder voorafgaande schriftelijke toestemming van de ander.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">4. Prijzen en prijswijziging</h2>
                                        <ol>
                                            <li>De opgegeven prijs is gebaseerd op de inkoopprijs en andere kostenfactoren. Wanneer een van deze niet voorzienbare kostprijs bestanddelen na de aanbieding, maar voor de levering wordt verhoogd, heeft Leading Apps het recht die verhogingen in alle redelijkheid aan de opdrachtgever in rekening te brengen.</li>
                                            <li>Alle prijzen zijn exclusief omzetbelasting (BTW) tenzij anders vermeldt.</li>
                                            <li>Indien Leading Apps op verzoek van de opdrachtgever een begroting voor kosten van derden opstelt, dan zal deze begroting slechts een indicatieve strekking hebben. Desgewenst kan Leading Apps namens de opdrachtgever offertes aanvragen.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">5. De uitvoering van de overeenkomst</h2>
                                        <ol>
                                            <li>Leading Apps zal zich inspannen de opdracht zorgvuldig uit te voeren, de belangen van de opdrachtgever naar beste weten te behartigen en te streven naar een voor de opdrachtgever bruikbaar resultaat. Voor zover noodzakelijk zal Leading Apps de opdrachtgever op de hoogte houden van de voortgang van de werkzaamheden.</li>
                                            <li>De opdrachtgever draagt er zorg voor dat alle gegevens, waarvan Leading Apps aangeeft dat deze noodzakelijk zijn of waarvan de opdrachtgever redelijkerwijs behoort te begrijpen dat deze noodzakelijk zijn voor het uitvoeren van de overeenkomst, tijdig aan Leading Apps worden verstrekt. Indien de voor de uitvoering van de overeenkomst benodigde gegevens niet tijdig aan Leading Apps zijn verstrekt, heeft Leading Apps het recht de uitvoering van de overeenkomst op te schorten en/of de uit de vertraging voortvloeiende extra kosten volgens de gebruikelijke tarieven aan de opdrachtgever in rekening te brengen.</li>
                                            <li>Het uitvoeren van tests, het aanvragen van vergunningen en het beoordelen of de instructies van de opdrachtgever voldoen aan de wettelijke normen behoren niet tot de opdracht van Leading Apps.</li>
                                            <li>Alvorens tot productie, verveelvoudiging of openbaarmaking wordt overgegaan dienen partijen elkaar in de gelegenheid te stellen de laatste modellen, prototypes of proeven van het ontwerp te controleren en goed te keuren. Op verzoek van Leading Apps dient de opdrachtgever zijn goedkeuring schriftelijk te bevestigen.</li>
                                            <li>Indien is overeengekomen dat de overeenkomst in fasen zal worden uitgevoerd kan Leading Apps de uitvoering van die onderdelen die tot een volgende fase behoren opschorten totdat de opdrachtgever de resultaten van de daaraan voorafgaande fase schriftelijk heeft goedgekeurd.</li>
                                            <li>Tenzij uitdrukkelijk schriftelijk anders is overeengekomen, brengt de tussen Leading Apps en de opdrachtgever gesloten overeenkomst tot het voeren van marketing werkzaamheden (o.a. SEO, SEA, Linkbuilding, Social media promotie etc.) voor Leading Apps slechts een inspanningsverplichting met zich mee. Leading Apps garandeert niet dat het door de opdrachtgever beoogde resultaat ook daadwerkelijk door de werkzaamheden van Leading Apps zal worden verwezenlijkt. Indien er door Leading Apps een prognose of verwachting van de omzet wordt uitgesproken dan is dit een inschatting, hieraan kunnen geen rechten worden ontleend.</li>
                                            <li>Indien en voor zover een goede uitvoering van de overeenkomst dit vereist, heeft Leading Apps het recht bepaalde werkzaamheden te laten verrichten door derden. De toepasselijkheid van artikel 7:404, 7:407 lid 2 en 7:409 BW wordt uitdrukkelijk uitgesloten.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">6. Wijziging van de opdracht</h2>
                                        <p>Indien een opdrachtgever wijziging wenst in de uitvoering van een verstrekte opdracht, is Leading Apps tot uitvoering hiervan slechts verplicht, indien dit redelijkerwijs nog mogelijk is. In dat geval is de opdrachtgever verplicht aan Leading Apps door haar reeds gemaakte kosten, ook indien deze voor de uitvoering van de opdrachtgever in haar definitieve vorm nodeloos zijn gemaakt, te vergoeden.</p>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">7. (Op)levering en termijnen</h2>
                                        <ol>
                                            <li>Een door Leading Apps opgegeven termijn voor het volbrengen van een opdracht heeft slechts een indicatieve strekking en is geen fatale termijn, tenzij uit de aard of de inhoud van de overeenkomst anders blijkt. Leading Apps is, ook bij een opgegeven termijn voor het volbrengen van de opdracht, eerst in verzuim nadat de opdrachtgever hem/haar bij aangetekend schrijven in gebreke heeft gesteld en nakoming binnen de in de ingebrekestelling genoemde, redelijke termijn uitblijft.</li>
                                            <li>ln geval Leading Apps (onderdelen/gedeelten van) het werk naar de opdrachtgever op welke wijze dan ook verzendt, mede begrepen - maar niet beperkt tot - toezending per post, koerier of via elektronisch berichtenverkeer, vindt deze verzending plaats voor rekening en risico van opdrachtgever.</li>
                                            <li>Indien partijen overeenkomen dat de overeenkomst wordt gewijzigd of aangevuld, kan het tijdstip van voltooiing van de uitvoering daardoor worden beïnvloed. Leading Apps zal de opdrachtgever hiervan op de hoogte stellen.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">8. Klachten</h2>
                                        <p>Klachten over de verrichte werkzaamheden dienen door de opdrachtgever binnen 8 dagen na ontdekking, doch uiterlijk binnen veertien (14) dagen na voltooiing van de betreffende werkzaamheden schriftelijk te worden gemeld aan Leading Apps.</p>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">9. Rechten van intellectuele eigendom en eigendomsrechten</h2>
                                        <ol>
                                            <li>Tenzij uitdrukkelijk schriftelijk is overeengekomen, behoort niet tot de opdracht het uitvoeren van onderzoek naar het bestaan van octrooirechten, merkrechten, tekening­ of modelrechten, auteursrechten en portretrechten van derden. Ditzelfde geldt voor een eventueel onderzoek naar de mogelijkheid van dergelijke beschermingsvormen voor de opdrachtgever.</li>
                                            <li>Alle rechten van intellectuele eigendom op alle krachtens de overeenkomst ontwikkelde of ter beschikking gestelde programmatuur zoals analyses, ontwerpen, documentatie, rapporten, offertes, het CMS en de ontwikkelde code alsmede voorbereidend materiaal daarvan, berusten uitsluitend bij Leading Apps of diens licentiegevers. Opdrachtgever verkrijgt uitsluitend de gebruiksrechten en bevoegdheden die bij deze voorwaarden of anderszins uitdrukkelijk worden toegekend en voor het overige zal hij de programmatuur of andere materialen niet verveelvoudigen of daarvan kopieën vervaardigen. Het is Leading Apps toegestaan technische maatregelen te nemen ter bescherming van de programmatuur. Indien Leading Apps door middel van technische bescherming de programmatuur heeft beveiligd, is het opdrachtgever niet toegestaan deze beveiliging te verwijderen of te ontwijken.</li>
                                            <li>Tenzij het werk er zich niet voor leent, is Leading Apps te allen tijde gerechtigd om haar naam op of bij het werk te (laten) vermelden of verwijderen. Zonder voorafgaande toestemming is het de opdrachtgever niet toegestaan het werk zonder vermelding van de naam van Leading Apps in productie te nemen, openbaar te maken of te verveelvoudigen.</li>
                                            <li>De in het kader van de opdracht door Leading Apps tot stand gebrachte werktekeningen, prototypes, maquettes, mallen, ontwerpschetsen, films, concept ontwerpen, logo's, huisstijlen, dvd's, en andere materialen of (elektronische) bestanden, blijven eigendom van Leading Apps, ongeacht of deze aan de opdrachtgever of aan derden ter hand zijn gesteld.</li>
                                            <li>Onverminderd het bepaalde in lid 3 van dit artikel verkrijgt de opdrachtgever het recht tot gebruik van de programmatuur in zijn bedrijf of organisatie. Indien en voor zover dit uitdrukkelijk schriftelijk is overeengekomen, kan de broncode van de programmatuur en de bij de ontwikkeling van de programmatuur voortgebrachte technische documentatie aan opdrachtgever ter beschikking worden gesteld en is de opdrachtgever gerechtigd in deze programmatuur wijzigingen aan te brengen.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">10. Gebruik en licentie</h2>
                                        <ol>
                                            <li>Leading Apps verleent de opdrachtgever het niet-exclusieve recht tot het gebruik van de programmatuur. Opdrachtgever zal de tussen partijen overeengekomen vergoedingen en gebruiksbeperkingen steeds stipt voldoen respectievelijk naleven. Onverminderd het overigens in deze algemene voorwaarden bepaalde, omvat het gebruiksrecht van de opdrachtgever uitsluitend het recht de programmatuur te laden en uit te voeren.</li>
                                            <li>Het gebruiksrecht is niet overdraagbaar. Het is de opdrachtgever niet toegestaan de programmatuur en dragers waarop deze is vastgelegd te verkopen, te verhuren, te sublicentiëren, te vervreemden of daarop beperkte rechten te verlenen of op welke wijze of voor welk doel dan ook ter beschikking van een derde te stellen, ook niet indien de betreffende derde de programmatuur uitsluitend ten behoeve van de opdrachtgever gebruikt, tenzij anders schriftelijk overeengekomen.</li>
                                            <li>De broncode van de programmatuur en de bij de ontwikkeling van de programmatuur voortgebrachte technische documentatie worden niet aan de opdrachtgever ter beschikking gesteld, tenzij anders schriftelijk overeengekomen.</li>
                                            <li>Het is de opdrachtgever niet toegestaan om zonder schriftelijke toestemming van Leading Apps veranderingen in ontwerpen of programmatuur, aan te (laten) brengen.</li>
                                            <li>Leading Apps heeft de vrijheid om ontwerpen te gebruiken voor haar eigen publiciteit of promotie.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">11. Honorarium</h2>
                                        <ol>
                                            <li>Naast het overeengekomen honorarium komen ook de kosten, die Leading Apps voor de uitvoering van de opdracht maakt, voor vergoeding in aanmerking.</li>
                                            <li>Indien Leading Apps door het niet tijdig of niet aanleveren van volledige, deugdelijke en duidelijke gegevens/materialen of door een gewijzigde hetzij onjuiste opdracht of briefing genoodzaakt is meer of andere werkzaamheden te verrichten, zullen deze werkzaamheden apart worden gehonoreerd, op basis van de gebruikelijk door Leading Apps gehanteerde honorariumtarieven.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">12. Facturatie en betaling</h2>
                                        <ol>
                                            <li>Leading Apps is gerechtigd voorafgaande aan de uitvoering van de overeenkomst van de opdrachtgever een voorschot te verlangen, welk voorschot op de laatste factuur in mindering zal worden gebracht. Voorschotten dienen onverwijld te worden voldaan.</li>
                                            <li>Betaling van facturen dient te geschieden binnen veertien (14) dagen na factuurdatum, op een door Leading Apps aan te geven wijze in de valuta waarin is gefactureerd.</li>
                                            <li>Na het verstrijken van veertien (14) dagen na de factuurdatum verkeert de opdrachtgever van rechtswege in verzuim zonder dat nadere ingebrekestelling daartoe is vereist.</li>
                                            <li>De opdrachtgever is vanaf het moment van in verzuim treden over het opeisbare bedrag een rente verschuldigd van 1% per maand, tenzij de wettelijke (handels)rente hoger is in welk geval de wettelijke (handels)rente geldt. Alle (buiten)gerechtelijke kosten welke Leading Apps maakt ter verkrijging van voldoening - zowel in als buiten rechte - komen vanaf dat moment voor rekening van de opdrachtgever. In dat geval is de Opdrachtgever een vergoeding verschuldigd van ten minste 15% van het openstaande bedrag, met een minimum van€ 75,00. Indien de werkelijk door Leading Apps gemaakte en te maken kosten boven dit bedrag uitstijgen, komen deze eveneens voor vergoeding in aanmerking.</li>
                                            <li>Leading Apps heeft het recht zijn honorarium volgens de volgende fasering in rekening te brengen voor verrichte werkzaamheden en gemaakte kosten ten behoeve van de uitvoering van de opdracht:
                                                <ul>
                                                    <li>Bij aanvang van de opdracht: 50%</li>
                                                    <li>Bij technische oplevering: 40%</li>
                                                    <li>Bij online plaatsen: 10%</li>
                                                </ul>
                                                Wanneer een andere fasering van toepassing is zal dit uitdrukkelijk in de offerte worden vermeldt.
                                            </li>
                                            <li>De opdrachtgever verricht de aan Leading Apps verschuldigde betalingen zonder korting of compensatie, behoudens verrekening met op de overeenkomst betrekking hebbende verrekenbare voorschotten, die hij aan Leading Apps heeft verstrekt.</li>
                                            <li>Vanaf het moment dat de opdrachtgever zijn betalingsverplichtingen uit hoofde van de overeenkomst niet (volledig) nakomt of anderszins in gebreke is, is het de opdrachtgever niet (langer) toegestaan de ter beschikking gestelde resultaten te gebruiken en komt elke in het kader van de opdracht aan de opdrachtgever verstrekte licentie te vervallen.</li>
                                            <li>In geval van liquidatie, faillissement of surseance van betaling van de opdrachtgever of een aanvraag daartoe zijn de vorderingen van Leading Apps en de verplichtingen van de opdrachtgever jegens Leading Apps onmiddellijk opeisbaar.</li>
                                            <li>Door de opdrachtgever gedane betalingen strekken steeds ter afdoening in de eerste plaats van alle verschuldigde rente en kosten, in de tweede plaats van opeisbare facturen die het langst openstaan, zelfs al vermeldt de opdrachtgever, dat de voldoening betrekking heeft op een latere factuur.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">13. Opzegging en ontbinding overeenkomst</h2>
                                        <ol>
                                            <li>Wanneer de opdrachtgever een overeenkomst opzegt, dient hij, naast een schadevergoeding, het honorarium en de gemaakte kosten met betrekking tot de tot dan verrichte werkzaamheden te betalen.</li>
                                            <li>Indien de overeenkomst door Leading Apps wordt ontbonden wegens een toerekenbare tekortkoming in de nakoming van de overeenkomst door de opdrachtgever, dient de opdrachtgever, naast een schadevergoeding, het honorarium en de gemaakte kosten met betrekking tot de tot dan verrichte werkzaamheden te betalen. Gedragingen van de opdrachtgever op grond waarvan van Leading Apps redelijkerwijs niet meer gevergd kan worden dat de opdracht wordt afgerond, worden in dit verband mede beschouwd als toerekenbare tekortkoming.</li>
                                            <li>De schadevergoeding bedoeld in de vorige twee leden van dit artikel zal tenminste omvatten de kosten voortvloeiend uit de door Leading Apps op eigen naam voor de vervulling van de opdracht aangegane verbintenissen met derden, alsmede 50% van het resterende deel van het honorarium welke de opdrachtgever bij volledige vervulling van de opdracht verschuldigd zou zijn.</li>
                                            <li>In geval van liquidatie, van (aanvrage van) surseance van betaling of faillissement, van beslaglegging - indien en voor zover het beslag niet binnen drie maanden is opgeheven - ten laste van de Opdrachtgever, van schuldsanering of een andere omstandigheid waardoor de Opdrachtgever niet langer vrijelijk over zijn vermogen kan beschikken, staat het Leading Apps vrij om de overeenkomst terstond en met directe ingang op te zeggen danwel de order of overeenkomst te annuleren, zonder enige verplichting zijnerzijds tot betaling van enige schadevergoeding of schadeloosstelling. De vorderingen van Leading Apps op de Opdrachtgever zijn in dat geval onmiddellijk opeisbaar.</li>
                                            <li>Indien de opdracht, om welke reden dan ook, voortijdig wordt beëindigd, is het de opdrachtgever niet (langer) toegestaan de hem ter beschikking gestelde ontwerpen te gebruiken en komt elke in het kader van de opdracht aan de opdrachtgever verstrekte licentie te vervallen.</li>
                                            <li>Wanneer de werkzaamheden van Leading Apps bestaan uit het bij herhaling verrichten van soortgelijke werkzaamheden, dan zal de daarvoor geldende overeenkomst, tenzij schriftelijk anders is overeengekomen, gelden voor twaalf (12) maanden. Na deze periode wordt de overeenkomst telkens met een (1) jaar stilzwijgend verlengd. De opzegging van de overeenkomst dient uiterlijk 30 november van het betreffende jaar gedaan te worden. Opzegging van deze overeenkomst dient schriftelijk te geschieden aan het adres van Leading Apps.</li>
                                            <li>Het opzeggen van een overeenkomst inzake hosting en/of domeinnamen is geregeld in de hiervoor gemaakte: algemene voorwaarden hosting.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">14. Garanties en vrijwaringen</h2>
                                        <ol>
                                            <li>Tenzij uitdrukkelijk anders overeengekomen, verleent Leading Apps verder geen garantie, direct of indirect, met betrekking tot diens diensten of goederen.</li>
                                            <li>Opdrachtgever vrijwaart Leading Apps of door Leading Apps bij de opdracht ingeschakelde personen tegen alle aanspraken van derden jegens Leading Apps op grond van of in verband met de opdracht en de uitvoering daarvan in overeenstemming met de opdracht alsmede voor alle aanspraken van derden voortvloeiend uit de toepassingen of het gebruik van het resultaat van de opdracht.</li>
                                            <li>De opdrachtgever vrijwaart Leading Apps voor aanspraken met betrekking tot rechten van intellectuele eigendom op door de opdrachtgever verstrekte materialen of gegevens, die bij de uitvoering van de opdracht worden gebruikt.</li>
                                            <li>Leading Apps kan voor haar werkzaamheden open source software gebruiken. De bepalingen inzake voorbehoud van intellectuele eigendomsrechten, o.a. licenties, gelden niet voorzover dat in strijd is met eventuele van toepassing zijnde open source licenties.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">15. Aansprakelijkheid</h2>
                                        <ol>
                                            <li>Leading Apps kan niet aansprakelijk worden gesteld voor:</li>
                                            <ul>
                                                <li>fouten in het materiaal dat door de opdrachtgever ter hand is gesteld;</li>
                                                <li>misverstanden of fouten ten aanzien van de uitvoering van de overeenkomst indien deze hun aanleiding of oorzaak vinden in handelingen van de opdrachtgever, zoals het niet tijdig of niet aanleveren van volledige, deugdelijke en duidelijke gegevens/materialen;</li>
                                                <li>fouten van door/namens de opdrachtgever ingeschakelde derden;</li>
                                                <li>gebreken in offertes van toeleveranciers of overschrijdingen van prijsopgaven van toeleveranciers;</li>
                                                <li>fouten in het ontwerp of de tekst/gegevens, indien de opdrachtgever, overeenkomstig het bepaalde in artikel 5 zijn goedkeuring heeft gegeven, dan wel in de gelegenheid is gesteld een controle uit te voeren en te kennen heeft gegeven aan een dergelijke controle geen behoefte te hebben;</li>
                                                <li>fouten of problemen die ontstaan zijn na aflevering en door gebruik of onderhoud van het product door anderen dan Leading Apps;</li>
                                                <li>schade wegens winstderving, werkstagnatie, verlies van zakelijke en/of andere gegevens(bestanden) of enige andere bedrijfsschade of geldelijk verlies, mogelijk samenhangend met het gebruik van het door Leading Apps geleverde.</li>
                                            </ul>
                                            <li>Indien Leading Apps aansprakelijk is voor schade, dan is die aansprakelijkheid beperkt tot vergoeding van directe schade en tot maximaal het factuurbedrag van de overeenkomst, althans dat gedeelte van de overeenkomst waarop de aansprakelijkheid betrekking heeft. Onder directe schade wordt uitsluitend verstaan:</li>
                                            <ul>
                                                <li>De redelijke kosten ter vaststelling van de oorzaak en de omvang van de schade, voor zover de vaststelling betrekking heeft op schade in de zin van deze algemene voorwaarden.</li>
                                                <li>De eventuele redelijke kosten gemaakt om de gebrekkige prestatie van Leading Apps aan de overeenkomst te laten beantwoorden, tenzij deze niet aan Leading Apps kunnen worden toegerekend.</li>
                                                <li>Redelijke kosten, gemaakt ter voorkoming of beperking van schade, voor zover de opdrachtgever aantoont dat deze kosten hebben geleid tot beperking van de directe schade als bedoeld in deze algemene voorwaarden.</li>
                                            </ul>
                                            <li>Leading Apps is nimmer aansprakelijk voor indirecte schade, daaronder begrepen gevolgschade, gederfde winst, gemiste besparingen en schade door bedrijfsstagnatie.</li>
                                            <li>Elke aansprakelijkheid vervalt door het verloop van een jaar vanaf het moment dat de opdracht is voltooid.</li>
                                            <li>De in deze algemene voorwaarden opgenomen beperkingen van de aansprakelijkheid voor directe schade zijn niet van toepassing indien de schade te wijten is aan opzet of grove schuld aan de zijde van Leading Apps.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">16. Bewaarplicht</h2>
                                        <ol>
                                            <li>De opdrachtgever is gehouden, indien redelijkerwijs mogelijk, kopieën van door hem verstrekte materialen en gegevens onder zich te houden tot de opdracht is vervuld. Indien de opdrachtgever dit nalaat, kan Leading Apps niet aansprakelijk worden gesteld voor schade die bij het bestaan van deze kopieën niet was opgetreden.</li>
                                            <li>Na het voltooien van de opdracht hebben noch de opdrachtgever noch Leading Apps jegens elkaar een bewaarplicht met betrekking tot de gebruikte materialen, gegevens en resultaten.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">17. Wijziging en vindplaats</h2>
                                        <ol>
                                            <li>Leading Apps behoudt het recht deze algemene leveringsvoorwaarden te wijzigen of aan te vullen.</li>
                                            <li>Wijzigingen gelden ook ten aanzien van reeds gesloten overeenkomsten met inachtneming van een termijn van dertig (30) dagen na schriftelijke bekendmaking van de wijziging.</li>
                                            <li>Indien de opdrachtgever niet akkoord gaat met de gewijzigde algemene voorwaarden, is hij gerechtigd de overeenkomst te ontbinden met ingang van de wijzigingsdatum van de algemene voorwaarden of binnen zeven (7) dagen na de ontvangstdatum van de wijziging van de algemene voorwaarden indien deze ontvangstdatum ligt na de ingangsdatum van de wijziging.</li>
                                            <li>Deze voorwaarden kunnen worden ingezien, gedownload en opgeslagen via: https://leadingapps.nl/voorwaarden. Van toepassing is steeds de laatst online geplaatste versie c.q. de versie zoals die gold ten tijde van het tot stand komen van de onderhavige overeenkomst.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">18. Overdracht van rechten en plichten</h2>
                                        <p>Het is de opdrachtgever niet toegestaan enig recht uit een met Leading Apps gesloten overeenkomst aan derden over te dragen, anders dan bij overdracht van zijn gehele onderneming.</p>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">19. Vertrouwelijke informatie</h2>
                                        <p>Partijen zijn gehouden feiten en omstandigheden, die in het kader van de opdracht aan de andere partij ter kennis komen, vertrouwelijk te behandelen. Derden, die bij de uitvoering van de opdracht worden betrokken, zullen ten aanzien van deze feiten en omstandigheden afkomstig van de andere partij aan eenzelfde vertrouwelijke behandeling worden gebonden.</p>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">20. Toepasselijk recht en forumkeuze</h2>
                                        <ol>
                                            <li>Op de overeenkomst tussen Leading Apps en de opdrachtgever gesloten is het Nederlands recht van toepassing.</li>
                                            <li>Geschillen die ontstaan naar aanleiding van een overeenkomst waarop deze algemene voorwaarden geheel of gedeeltelijk van toepassing zijn, of naar aanleiding van andere overeenkomsten die een uitvloeisel zijn van een dergelijke overeenkomst, zullen worden beslecht door de bevoegde rechter van het arrondissement van de vestigingsplaats van Leading Apps, tenzij een dwingende wetsbepaling zich hiertegen verzet. Dit laat onverlet dat Leading Apps met de opdrachtgever kan overeenkomen het geschil te laten beslechten door middel van onafhankelijke arbitrage.</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-2 mb-4 momt-2">
                        <div className="row g-0">
                            <Link to="/voorwaarden" >
                                <div className="col-12 mh-centered mb-3">
                                    <h6 className={"black voorwaarden-link " + isSelected("voorwaarden")}>Algemene voorwaarden</h6>
                                </div>
                            </Link>
                            <Link to="/hosting-voorwaarden">
                                <div className="col-12 mh-centered mb-3">
                                    <h6 className={"black voorwaarden-link " + isSelected("hosting-voorwaarden")}>Hosting voorwaarden</h6>
                                </div>
                            </Link>
                            <Link to="/privacy" className="voorwaarden-link-wrapper">
                                <div className="col-12 mh-centered mb-3">
                                    <h6 className={"black voorwaarden-link " + isSelected("privacy")}>Privacyverklaring</h6>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

            </section>

            <Footer />

        </>

    )
}

export default AlgemeneVoorwaarden;